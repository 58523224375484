import { BaseComponent } from '@base/component/base.component';
import { OnInit, Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '@authentication/service/authentication.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { ForgotPassword } from '@authentication/model/authentication.model';
import { HeaderService } from '@header/services/header.service';
declare var $: any;
@Component({
    selector: 'app-authentication',
    templateUrl: '../view/forgotpassword.component.html',
    styleUrls: ['../css/authentication.component.css']
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit {
    public authenticationService: AuthenticationService;
    public is_loading: boolean;

    public updatePasswordform: FormGroup = new FormGroup({
        otp: new FormControl('', [Validators.required, Validators.maxLength(6)]),
        new_password: new FormControl('', [Validators.required,Validators.pattern(
            "(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}"
          )]),
        confirm_new_password: new FormControl('', [Validators.required])
    }, this.passwordMatchValidator);



    forgotPasswordform: FormGroup = new FormGroup({
        email_id: new FormControl('', [Validators.required, Validators.email])
    });

    public passwordMatchValidator(g: FormGroup) {
        return g.get('new_password').value === g.get('confirm_new_password').value ? null : { 'mismatch': true };
    }

    constructor() {
        super();
    }

    ngOnInit() {
        this.authenticationService = ServiceLocator.injector.get(
            AuthenticationService
        );
    }

    resetUserPassword(forgotPasswordform: FormGroup): void {
        this.is_loading = true;
        const forgot = new ForgotPassword();
        const forgotPasswordObject = {
            emailId: forgotPasswordform.value['email_id']
        } 
        BaseComponent.forgotPasswordEmail = this.forgotPasswordform.value['email_id'];
        this.authenticationService.forgotPassword(forgotPasswordObject).subscribe(res => {
            if (res !== undefined) {
                if (res.success === true) {
                    localStorage.removeItem('videoUrl');
                    this.router.navigate(['set-new-password']);
                    this.successResponse(res.message);
                    this.is_loading = false;
                } else {
                    this.errMessageResponse(res.message);
                    this.is_loading = false;
                }
            }
        }, err => {
            
            //this.errMessageResponse(res.msg);
            this.is_loading = false;
        });
    }

}