import { environment } from '@environments/environment';

// Set API URL
export let API_PATH = environment.apiUrl;
export let API_PATH_DOLLYWOOD = environment.apiUrlDollywood;
export let API_PATH_CAROUSAL = environment.apiUrlCarousal;

// Set API REQUEST URL
export let API_REFERENCE = {
  authentication: {
    sendOTP: 'v1/sendotp', //Tejash
    verfyOTP: 'v1/verifyotp', //Tejash
  },
  location: {
    geo: 'geo', //Tejash
  },
  user: {
    login: 'v1/login', //Tejash
    forgotPassword: 'v1/forgotpassword', //Tejash
    updatePassword: 'v1/updatepassword', //Tejash
    usertoken: 'v1/token/guest', //Tejash
    socialauth: 'socialauth',
    checkuser: 'v1/checkuser', //Tejash
    register: 'v1/register' // Tejash
  },
  profile: {
    get: 'v1/profile/', //Tejash
    update: 'v1/profile/update', //Tejash
    passwordChange: 'v1/changepassword', //Tejash
    uploadProfilePicture: 'v1/profile/uploadfile/', //Tejash
    updateProfilePicture: 'v1/profile/update/picture', //Tejash
    getwatchlist: 'v1/watchlist',   //Tejash
    getfavourite: 'v1/assetfavourite',  //Tejash
    removeProfile: 'v1/profile/remove/picture', //Tejash
    usertoken: 'v1/token/user', //Tejash
    orderListing: 'v1/order',  //Tejash
    downloadInvoice: '/v1/downloadinvoice' //Tejash
  },
  homepage: {
    navigation: 'v1/navigation', //Tejash
    carousel: 'v1/carousel' //Tejash
  },
  pagecategories: {
    pagecategorie: 'v1/pagecategory', //Tejash
    defaultlist: 'v1/pagecategory/listing' //Tejash
  },
  asset: {
    assetdetails: 'v1/asset/', //Tejash
    addfavourite: 'v1/assetfavourite', // kevin,
    assetgroupfavourite: 'v1/assetgroupfavourite', //Tejash
    removeAssetgroupfavourite: 'v1/assetgroupfavourite/delete', //Tejash
    removefavourite: 'v1/assetfavourite/delete', //kevin
    addwatchlist: 'v1/watchlist', //Tejash
    relatedvideo: 'v1/asset/related', //kevin
    suggestedvideos: 'v1/asset/suggested',//kevin
    castcrews: 'v1/cast', //Tejash
    bookmark: 'v1/bookmark' //Tejash

  },
  assetGroup: {
    assetgroupdetails: 'v1/assetgroup', //Tejash
    assetlist: 'v1/listing',  //Tejash
    assetcategoty: 'v1/assetcategory'  //Tejash
  },
  search: {
    search: 'v1/search' //Tejash
  },
  footer: {
    footergenre: 'v1/genre', //Tejash
    genrevideos: 'v1/genre/video', //Tejash
    footerlinks: 'v1/footerlink', //Tejash
    content: 'v1/footerlink/', //Tejash
    contact: 'v1/contact' //Tejash
  },
  vuuzleTv: {
    listing: 'v1/listing',
    channel: 'v1/asset/channel/schedule'
  },
  payment: {
    subscription: 'v1/subscription', //Tejash
    placeorder: 'v1/order' //Tejash
  }
};

// API REQUEST METHOD
export let METHOD_REFERENCE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};
