import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';
import { Carousal, CurousalResponse } from '@home/model/carousal';

@Injectable()
export class HomeService {

    public apiService: ApiService;
    public baseComponent: BaseComponent;
    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    }

    public getPageCategories(pageCategoryObject): Observable<any> {
        //const filter =  encodeURI(JSON.stringify(pageCategoryObject));
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['pagecategories']['pagecategorie'] + pageCategoryObject,
            {},
            null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getMainCarousal(carouselObject): Observable<CurousalResponse> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['homepage']['carousel'] + carouselObject,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getDefualtPageList(pageCategoryListingObject): Observable<any> {
        //const filter =  encodeURI(JSON.stringify(pageCategoryListingObject));
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['pagecategories']['defaultlist']+ pageCategoryListingObject,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
}