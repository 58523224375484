import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';

@Injectable()
export class AssetGroupDetailService {
    public apiService: ApiService;
    public baseComponent: BaseComponent;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    }

    public getAssetGroupDetails(urlParam): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['assetGroup']['assetgroupdetails'] + urlParam,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getAssetListing(urlParam): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['assetGroup']['assetlist'] + urlParam,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getAssetCategoty(urlParam): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['assetGroup']['assetcategoty'] + urlParam,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
}