import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';

@Injectable()
export class FooterService {

    public apiService: ApiService;
    public baseComponent: BaseComponent;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    }

    public getFooterGenres(): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['footer']['footergenre'],
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getFooterVideos(genreFiterString): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['footer']['genrevideos'] + genreFiterString,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getFooterLinks(): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['footer']['footerlinks'],
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public sendContactUs(urlParam): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['footer']['contact'],
            urlParam, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
}