import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { Router } from '@angular/router';
import { ProfileService } from '@profile/service/profile.service';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  GetProfileResponse,
  GetUserProfile,
  ChangePassword,
  GetWatchlistData,
  GetfavouriteData,
  AllData,
  ProfileData
} from '@profile/model/profile.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HeaderService } from '@header/services/header.service';
import { PageData } from '@home/model/pagecategory.model';
import { VideoPage } from '@video-page/model/video-page';
import { VideoPageService } from '@video-page/services/videopage.service';
import { AuthService } from 'angularx-social-login';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: '../view/profile.component.html',
  styleUrls: ['../css/profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public profileService: ProfileService;
  public profile: ProfileData;
  public pass: any;
  public newpass: any;
  public isLogin: boolean;
  headerService: HeaderService;
  watchlistData: GetWatchlistData;
  favouriteData: GetfavouriteData;
  videopageData: VideoPage;
  public videopageService: VideoPageService;
  removeTofavouriteData: AllData;
  refereshToken: {};
  orderListingData = [];

  public profilePasswordForm: FormGroup = new FormGroup(
    {
      password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required,Validators.pattern(
        "(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}")]),
      confirm_new_password: new FormControl('', [Validators.required])
    },
    this.passwordMatchValidator
  );

  public passwordMatchValidator(g: FormGroup) {
    return g.get('new_password').value === g.get('confirm_new_password').value
      ? null
      : { mismatch: true };
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    window.scroll(0, 0);

    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    // $('.tablist ul li a').click(function () {

    //   $('.tablist ul li').removeClass('active');
    //   $(this).parent('li').addClass('active');

    //   const active_div = $(this).attr('dtat-target');
    //   $('.tabing').hide();
    //   $('#' + active_div).show();

    // });

    $('.seasons-list li').click(function () {
      $('.seasons-list li').removeClass('activeSeasons');
      $(this).addClass('activeSeasons');
    });

    this.isLogin = BaseComponent.isLogin;
    // this.isLogin = true;
    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.getfavouritAndWatchlist();
    this.getProfile();
    this.getOrderListing();
  }

  constructor(public router: Router, private authService: AuthService,
              private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
  }

  public menuClick() {
    $('.tablist ul li a').click(function () {
      $('.tablist ul li').removeClass('active');
      $(this)
        .parent('li')
        .addClass('active');

      var active_div = $(this).attr('dtat-target');
      $('.tabing').hide();
      $('#' + active_div).show();
    });

    $('.seasons-list li').click(function () {
      $('.seasons-list li').removeClass('activeSeasons');
      $(this).addClass('activeSeasons');
    });
  }
  public gotoEditProfile(url): void {
    const myurl = `${url}`;
    this.router.navigateByUrl(myurl);
  }
  public signOut(): void {

    this.headerService.getToken().subscribe(
      res => {
        if (res !== undefined) {
          this.setGuestToken('Bearer ' + res);

          var body = res;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);

          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.guestTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();

          this.headerService.setLogin.emit(false);
          this.headerService.reloadHeader.emit(true);
          localStorage.removeItem('user');
          localStorage.removeItem('user_token');
          localStorage.removeItem('videoUrl');
          BaseComponent.baseUser = undefined;
          BaseComponent.isLogin = false;
          BaseComponent.userTime = '';
          this.router.navigate(['', 'home']);
        }
      },
      err => {
        
        this.headerService.setLogin.emit(false);
        this.headerService.reloadHeader.emit(true);
        localStorage.removeItem('user');
        localStorage.removeItem('user_token');
        BaseComponent.baseUser = undefined;
        BaseComponent.isLogin = false;
        BaseComponent.userTime = '';
        this.router.navigate(['', 'home']);
      }
    );


  }

  // public removeTofavourite(): void {
  //   const pageRequest = {
  //     user_id: this.getUserId(),
  //     asset_id: this.videopageData.path
  //   };
  //   this.videopageService.removeTofavourite(pageRequest).subscribe(
  //     res => {
  //       if (res !== undefined) {
  //         this.successResponse(res.msg);
  //         this.videopageData.is_favourite = false;
  //       }
  //     },
  //     err => {
  //       
  //     }
  //   );
  // }
  public getProfile(): void {
    const getUserProfile = new GetUserProfile();
    getUserProfile.uid = BaseComponent.user;
  

    this.profileService.getUserProfile(getUserProfile.uid).subscribe(
      res => {
        if (res !== undefined) {
          BaseComponent.userEmail = res.data[0].emailId;
          BaseComponent.userFName = res.data[0].firstName;
          BaseComponent.userLName = res.data[0].lastName;
          this.profile = res.data[0];
        }
      },
      err => {
        
      }
    );
  }

  public changePassword(profilePasswordForm: FormGroup): void {
    const changepass = new ChangePassword();
    changepass.emailId = BaseComponent.userEmail
    changepass.password = this.profilePasswordForm.value['password'];
    changepass.newPassword = this.profilePasswordForm.value['new_password'];
    if (changepass.password !== changepass.newPassword) {
      this.profileService.createNewPassword(changepass).subscribe(
        res => {
          if (res !== undefined) {
            if (res.success == true) {
              this.successResponse(res.message);
              this.profilePasswordForm.reset();
              $('#changePass').modal('hide');
            } else {
              this.errMessageResponse(res.message);
            }
          }
        },
        err => {
          
        }
      );
    } else {
      this.warningMessageResponse('Old password and new password must be diffrent');
    }

  }

  public getfavouritAndWatchlist(): void {
    const requestWatchlistData = {
      "deviceTypeId": "1"
    };

    // const requestFavouriteData = {
    //   "userId": this.getUserId()
    // };

    let fiterWatchlistString = "?filter="+encodeURI(JSON.stringify(requestWatchlistData));
    // let fiterFavouriteString = "?filter="+encodeURI(JSON.stringify(requestFavouriteData)); 
    this.profileService.getWatchlist(fiterWatchlistString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.watchlistData = res.data;
        } else if (res !== undefined && res.success === false) {
        }
      },
      err => {
        
      }
    );

    this.profileService.getfavourite().subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.favouriteData = res.data;
        }
      },
      err => {
        
      }
    );
  }

  getOrderListing() {
    // const orderListingData = {
    //   "userId": this.getUserId()
    // };

    // let fiterWatchlistString = "?filter="+encodeURI(JSON.stringify(orderListingData));
    
    this.profileService.getOrderListing().subscribe(
      res => {
        if(res != undefined && res.success == true) {
          this.orderListingData = res.data.data
        }
    })
  }

  public gotoVideoPage(pagedata: PageData): void {
    // if (pagedata.type === 'asset') {
      // this.router.navigate(['/video-page'], {
      //   queryParams: { path: pagedata.path }
      // });
      this.router.navigate(['/video', pagedata.title, pagedata.path]);
    // } else if (pagedata.type === 'asset_group') {
    //   this.router.navigate(['/season', pagedata.path]);
    // }
  }

  public downloadInvoice (invoice) {
    const invoiceRequest = {
      invoiceId: invoice.orderId
    };
    let fiterString = "?filter=" + encodeURI(JSON.stringify(invoiceRequest));
    this.profileService.downloadInvoice(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          // pdf download start
          const linkSource = 'data:application/pdf;base64,' + res.data;
          const downloadLink = document.createElement("a");
          const fileName = "sample.pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          // pdf download end
          // imageConvert start
          // var a = document.createElement("a"); //Create <a>
          // a.href = "data:image/png;base64," + res.data; //Image Base64 Goes here
          // a.download = "Image.png"; //File name Here
          // a.click(); 
          // imageConvert end
        }
      })
  }

  public removeTofavourite(assetItem: AllData): void {
    let index: any;
    const pageRequest = {
      assetId: assetItem.path
    };
    this.videopageService.removeTofavourite(pageRequest).subscribe(
      res => {
        if (res !== undefined) {
          this.successResponse(res.message);
          index = this.favouriteData.data.indexOf(assetItem);
          this.favouriteData.data.splice(index, 1);
        }
      },
      err => {
        
      }
    );
  }


  public setremoveTofavouriteData(setdata: AllData): void {
    this.removeTofavouriteData = setdata;
  }
}
