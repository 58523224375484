import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@base/component/base.component';
import { HttpCancelService } from './cancel_service';
declare var $: any;

@Injectable()
export class InterceptorService extends BaseComponent implements HttpInterceptor {

    constructor() {
        super();

    }
    protected httpCancelService: HttpCancelService;
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    count: number = 1;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            // if (event instanceof HttpResponse) {

            //     return event;
            // }
            return event;
        }

        ),
            catchError(err => {
                
                // if (err.status === 0) {
                if (err.status === 0 || err.status === 401) {
                    // this.warningMessageResponse('Unauthorized user');

                    localStorage.removeItem('user_token');
                    setTimeout(() => {
                        if (BaseComponent.baseUser !== undefined &&
                            Object.keys(BaseComponent.baseUser).length !== 0) {
                            localStorage.setItem('auth_msg', 'Unauthorized user');
                            this.router.navigate(['auth']);
                        } else {
                            // if (this.count === 1) {
                            //     this.warningMessageResponse('Something bad happen');
                            // }
                            this.count = this.count + 1;
                            // next.handle(request).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
                            // this.ngUnsubscribe.next();
                        }

                    }, 1000);

                }
                return throwError(err);
            }
            ));
    }
}