
import { Component, OnInit,Input,EventEmitter,Output} from '@angular/core';


@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css']
})
export class ModelComponent implements OnInit {

  
  @Output() isContinueEditingSend = new EventEmitter<any>();
  @Output() isContentReadyToReviewSend = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  onReviewAction(){

    this.isContinueEditingSend.emit();
    
  }

}
