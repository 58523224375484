import { Component } from "@angular/core";
import { environment } from "@environments/environment";
import { Router, NavigationEnd } from "@angular/router";
import { OnDestroy, Renderer2 } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "../view/app.component.html",
  styleUrls: ["../css/app.component.css"],
})
export class AppComponent {
  hideDiv = false;
  title = "vuuzle-frontend";
  hideFooterHeader = false;

  constructor(private router: Router, private render: Renderer2) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects);
        if (
          event.urlAfterRedirects == "/auth" ||
          event.urlAfterRedirects == "/profile" ||
          event.urlAfterRedirects == "/profile/edit-profile"
        ) {
          this.hideFooterHeader = true;
          this.render.removeClass(document.body, "body-padd");
        } else {
          this.hideFooterHeader = false;
          this.render.addClass(document.body, "body-padd");
        }
        this.hideDiv = true;
        (<any>window).ga("set", "page", event.urlAfterRedirects);
        (<any>window).ga("send", "pageview");
      }
    });
  }
}
