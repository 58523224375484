export const environment = {
  production: false,
  imagePrefix: "assets/images/",
  // apiUrl: 'https://vuuzle.admin.preprod.dctinc.net/api/v1/',       //preprod
  // apiUrl: "https://vuuzle.dcafecms.com/api/v1/",                   //prod
  // apiUrl: 'https://vuzzle.dev.dctinc.net/api/v1/',                 //dev
  apiUrl: "https://oo5ru1ioc1.execute-api.ap-south-1.amazonaws.com/jingu-dev/", //node dev
  // apiUrl: 'https://vuuzleapi.dcafecms.com/', //node prod
  apiUrlDollywood: "https://dollywoodplay.admin.demo.dctinc.net/api/v1/",
  apiUrlCarousal: "https://hungamakids.admin.stage.dctinc.net/api/v1/",
  // googleAuthKey: '247746380086-fbrglglfl6lvcn575u82248khpbef1j2.apps.googleusercontent.com', //prod
  googleAuthKey:
    "695989545489-ehsia82b9l31723f82dfilfm9rtfbo41.apps.googleusercontent.com", //dev
  // facebookAuthId: "2473374139418803", //prod
  facebookAuthId: "2239905339471156", // dev
  stripeKey: "pk_test_Kxjj6yhJEOF6Nj2BHDKtUc4l00T2rCAVpJ",
};
