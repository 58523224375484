import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { BaseComponent } from "@base/component/base.component";
import { EditProfileService } from "../service/edit-profile.service";
import { ServiceLocator } from "@base/constants/service-locator";
import { UpdateProfile } from "../model/edit-profile.model";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ProfileService } from "@profile/service/profile.service";
import {
  GetProfileResponse,
  GetUserProfile,
} from "@profile/model/profile.model";
import { HttpHeaders } from "@angular/common/http";
import { HeaderService } from "@header/services/header.service";
import {
  BsDaterangepickerDirective,
  BsDatepickerConfig,
} from "ngx-bootstrap/datepicker/public_api";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-edit-profile",
  templateUrl: "../view/edit-profile.component.html",
  styleUrls: ["../css/edit-profile.component.css"],
})
export class EditProfileComponent extends BaseComponent implements OnInit {
  @ViewChild("dp", { static: false }) datepicker: BsDaterangepickerDirective;
  bsConfig: Partial<BsDatepickerConfig>;

  maxDate = new Date();

  public editProfileService: EditProfileService;
  public userFName: string;
  public userLName: string;
  public email: string = "";
  profileService: ProfileService;
  public profile: GetProfileResponse;
  public userID: string;
  public fileToUpload: string;
  fileUrl: string = this.imagePrefix + "blank-profile-picture-973460_640.png";
  public headerService: HeaderService;
  uploadProfileRes: any = {};
  imageFile: any = {};

  public updateProfileForm: FormGroup = new FormGroup({
    first_name: new FormControl("", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    dob: new FormControl("", [Validators.required]),
  });

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.profileService = ServiceLocator.injector.get(ProfileService);
  }

  ngOnInit() {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    this.userID = this.getUserId();
    this.userFName = BaseComponent.userFName;
    this.userLName = BaseComponent.userLName;

    this.editProfileService = ServiceLocator.injector.get(EditProfileService);
    this.getProfile();
    this.headerService = ServiceLocator.injector.get(HeaderService);
  }

  public goToHomePage() {
    //const navigationData = this.navigationData.data;
    //for (const navigate of navigationData) {
    //if (navigate.title.toUpperCase() === "HOME") {
    // this.router.navigate([''], {
    //   queryParams: { path: navigate.path, type: navigate.type }
    // });
    this.router.navigate(["home"]);
    return;
    // }
    //}
  }
  public getProfile(): void {
    const getUserProfile = new GetUserProfile();
    getUserProfile.uid = this.getUserId();

    this.profileService.getUserProfile(getUserProfile.uid).subscribe(
      (res) => {
        if (res !== undefined) {
          this.updateProfileForm.controls["first_name"].setValue(
            res.data[0].firstName
          );
          this.updateProfileForm.controls["last_name"].setValue(
            res.data[0].lastName
          );
          this.updateProfileForm.controls["email"].setValue(
            res.data[0].emailId
          );
          const dateOfBirth = moment(res.data[0].dateOfBirth).format(
            "MM/DD/YYYY"
          );
          this.updateProfileForm.controls["dob"].setValue(dateOfBirth);
          BaseComponent.userEmail = res.data[0].emailId;
          BaseComponent.userFName = res.data[0].firstName;
          BaseComponent.userLName = res.data[0].lastName;
          BaseComponent.baseUser = res.data[0];
          this.email = BaseComponent.baseUser.emailId;
          this.profile = res;
          if (res.data[0].picture !== "" && res.data[0].picture !== null) {
            this.fileUrl = res.data[0].filePath;
          }
        }
      },
      (err) => {}
    );
  }

  openImagepiker(): void {
    $("#imgupload").trigger("click");
  }

  public updateProfile(updateProfileForm: FormGroup) {
    const profile = new UpdateProfile();
    // profile.emailId = this.updateProfileForm.value['email'];
    profile.id = this.getUserId();
    profile.firstName = this.updateProfileForm.value["first_name"];
    profile.lastName = this.updateProfileForm.value["last_name"];
    profile.dateOfBirth = moment(this.updateProfileForm.value["dob"]).format(
      "YYYY-MM-DD"
    );

    if (this.uploadProfileRes.data) {
      const profileObj = {
        fileName: this.imageFile.name,
        fileType: this.imageFile.type,
        filePath: this.uploadProfileRes.data.path,
      };
      this.editProfileService
        .updateUserProfileImage(profileObj)
        .subscribe((data) => {});
    }

    this.editProfileService.updateProfile(profile).subscribe(
      (res) => {
        if (res !== undefined) {
          if (res.success == true) {
            this.uploadProfileRes = {};
            this.successResponse(res.message);
            this.router.navigate(["profile"]);
          } else {
            this.errMessageResponse(res.message);
          }
        }
      },
      (err) => {}
    );
  }

  public uploadProfileImage(event) {
    this.imageFile = event.target.files[0];
    const formData = new FormData();
    formData.set("picture", event.target.files[0]);
    this.editProfileService
      .uploadUserProfileImage(formData, this.userID)
      .subscribe(
        (res) => {
          if (res !== undefined) {
            if (res.success == true) {
              this.fileUrl = "";
              this.fileUrl = res.data.path;
              this.uploadProfileRes = res;
              BaseComponent.baseUser.picture = this.fileUrl;
              this.headerService.setProfileImg.emit(this.fileUrl);
              // this.successResponse(res.message);
              $("#imgupload").val("");
              this.cdRef.detectChanges();
            } else {
              this.errMessageResponse(res.message);
              $("#imgupload").val("");
            }
          }
        },
        (err) => {}
      );
  }

  public removeProfile() {
    const requestData = { userId: this.getUserId() };

    this.editProfileService.removeProfile(requestData).subscribe(
      (res) => {
        if (res !== undefined && res.success === true) {
          this.uploadProfileRes = {};
          this.fileUrl =
            this.imagePrefix + "blank-profile-picture-973460_640.png";
          BaseComponent.baseUser.picture = this.fileUrl;
          this.headerService.setProfileImg.emit(this.fileUrl);
          this.successResponse(res.message);
        }
      },
      (err) => {}
    );
  }
}
