import { BaseModule } from "@base/module/base.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HeaderComponent } from "@header/component/header.component";
import { RouterModule } from "@angular/router";
import { from } from "rxjs";
import { HeaderService } from "@header/services/header.service";

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, RouterModule, BaseModule],
  exports: [HeaderComponent],
  providers: [HeaderService],
})
export class HeaderModule {}
