import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "@base/component/base.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthenticationService } from "@authentication/service/authentication.service";
import {
  VerifyOtp,
  SignIn,
  ForgotPassword,
  registerRequest,
} from "@authentication/model/authentication.model";
import { ServiceLocator } from "@base/constants/service-locator";
import { HeaderService } from "@header/services/header.service";
import { OnDestroy, Renderer2 } from "@angular/core";
import {
  AuthService,
  FacebookLoginProvider,
  SocialUser,
  GoogleLoginProvider,
} from "angularx-social-login";
import {
  BsDatepickerConfig,
  BsDaterangepickerDirective,
} from "ngx-bootstrap/datepicker/public_api";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-authentication",
  templateUrl: "../view/authentication.component.html",
  styleUrls: ["../css/authentication.component.css"],
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
  @ViewChild("dp", { static: false }) datepicker: BsDaterangepickerDirective;
  bsConfig: Partial<BsDatepickerConfig>;

  maxDate = new Date();

  getLocation: any = {};

  public authenticationService: AuthenticationService;
  headerService: HeaderService;
  public otp: string;
  public deviceType: number;
  public isLogin: boolean;
  public is_loading: boolean;
  refereshToken: {};
  otpEmail: any;
  lastDestinationUrl = "";
  // private myDatePickerOptions: IMyOptions = {

  //   dateFormat: 'dd.mm.yyyy',

  // };

  public registrationForm: FormGroup = new FormGroup({
    first_name: new FormControl("", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    dob: new FormControl("", [Validators.required]),
    password: new FormControl("", [
      Validators.required,
      Validators.pattern(
        "(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}"
      ),
    ]),
  });

  public loginform: FormGroup = new FormGroup({
    email_id: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required]),
  });

  public otpform: FormGroup = new FormGroup({
    otp: new FormControl("", [Validators.required]),
  });

  constructor(private authService: AuthService, private render: Renderer2) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
  }

  ngOnInit() {
    this.lastDestinationUrl = localStorage.getItem("videoUrl");
    localStorage.setItem("check", "true");

    if (localStorage.getItem("check") == "true") {
      this.headerService.getGeo().subscribe((data) => {
        if (data.success == true) {
          this.getLocation = data.data;
        }
      });
    }

    if (localStorage.getItem("user_token") !== null) {
      this.router.navigate(["", "home"]);
    }

    var auth_msg = localStorage.getItem("auth_msg");
    if (localStorage.getItem("user_token") === null) {
      if (auth_msg !== "" && auth_msg === "Unauthorized user") {
        this.headerService.getToken().subscribe((res) => {
          if (res !== undefined) {
            this.setGuestToken("Bearer " + res.data["token"]);

            var body = res.data["token"];
            var base64Url = body.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
              atob(base64)
                .split("")
                .map(function (c) {
                  return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken["exp"] * 1000);
            BaseComponent.guestTime = moment(dt)
              .subtract(50, "seconds")
              .format("HH:mm:ss")
              .toString();
          }
        });

        localStorage.removeItem("auth_msg");
        this.headerService.setLogin.emit(false);
        this.headerService.reloadHeader.emit(true);
        localStorage.removeItem("user");
        localStorage.removeItem("user_token");
        BaseComponent.baseUser = undefined;
        BaseComponent.isLogin = false;
        // this.warningMessageResponse(auth_msg);
      }
    }

    this.isLogin = BaseComponent.isLogin;
    this.authenticationService = ServiceLocator.injector.get(
      AuthenticationService
    );
    this.authenticationService = ServiceLocator.injector.get(
      AuthenticationService
    );
    BaseComponent.isSignUp = true;
    var self = this;
    $(".login-controll").click(function () {
      const openDiv = $(this).attr("data-attr");
      $(".form-warper").hide();
      $(openDiv).show();
    });

    $(".auth-class").click(function () {
      $(".auth-class").removeClass("active");
      $(this).addClass("active");
    });
    $(".tgBUtton").click(function () {
      self.registrationForm.reset();
      var own_hid = $(this).attr("data-hidden");
      var own_open = $(this).attr("data-attr");
      var other_hid = $(this).attr("data-hide");
      var other_open = $(this).attr("data-open");

      $("." + own_hid).hide();
      $("#" + own_open).show();

      $("#" + other_hid).hide();
      $("." + other_open).css("display", "flex");
    });

    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }

  ngOnDestroy() {
    localStorage.setItem("check", "false");
  }

  public goToHomePage() {
    //const navigationData = this.navigationData.data;
    //for (const navigate of navigationData) {
    //if (navigate.title.toUpperCase() === "HOME") {
    // this.router.navigate([''], {
    //   queryParams: { path: navigate.path, type: navigate.type }
    // });
    this.router.navigate(["home"]);
    return;
    // }
    //}
  }

  public gotoForgotPassword(): void {
    this.router.navigateByUrl("forgot-password");
  }

  public checkRegisteredUser(registrationFormValue: FormGroup): void {
    //Done
    const pageRequest = { emailId: registrationFormValue.value["email"] };
    this.authenticationService
      .checkRegistreUser(pageRequest)
      .subscribe((res) => {
        if (res !== undefined && res.success === true) {
          this.errMessageResponse(res.message);
          return;
        } else if (res !== undefined && res.success === false) {
          this.registerNewUser();
          // this.sendVerificationOTP(registrationFormValue.value['email']);
        }
      });
  }

  sendVerificationOTP(): void {
    //Done
    this.is_loading = true;
    const resendOtpObject = {
      emailId: this.otpEmail,
    };
    this.authenticationService.sendOTPEmail(resendOtpObject).subscribe(
      (response) => {
        if (response !== undefined) {
          if (response.success == true) {
            $("#otp").modal("show");
            this.successResponse(response.message);
            this.is_loading = false;
          } else {
            this.errMessageResponse(response.message);
            this.is_loading = false;
          }
        } else {
        }
      },
      (err) => {
        this.is_loading = false;
      }
    );
  }

  public resendVerificationOTP(): void {
    this.is_loading = true;

    const resendOtpObject = {
      emailId: this.otpEmail,
    };

    this.authenticationService.sendOTPEmail(resendOtpObject).subscribe(
      (response) => {
        if (response !== undefined) {
          if (response.success === true) {
            this.successResponse(response.message);
            this.is_loading = false;
          } else {
            this.errMessageResponse(response.message);
            this.is_loading = false;
          }
        }
      },
      (err) => {
        this.is_loading = false;
      }
    );
  }

  registerNewUser(): void {
    this.is_loading = true;
    const registerObj = new registerRequest();
    // verifyOtp.email = this.registrationForm.value['email'];
    // verifyOtp.device_type = '2';
    // verifyOtp.first_name = this.registrationForm.value['first_name'];
    // verifyOtp.last_name = this.registrationForm.value['last_name'];
    // verifyOtp.password = this.registrationForm.value['password'];
    // verifyOtp.dob = moment(this.registrationForm.value['dob']).format('MM DD YYYY');
    // verifyOtp.otp = this.otpform.value['otp'];

    // const registerRequestObject = {
    (registerObj.emailId = this.registrationForm.value["email"]),
      (registerObj.password = this.registrationForm.value["password"]),
      (registerObj.firstName = this.registrationForm.value["first_name"]),
      (registerObj.lastName = this.registrationForm.value["last_name"]),
      (registerObj.dateOfBirth = moment(
        this.registrationForm.value["dob"]
      ).format("YYYY-MM-DD")),
      (registerObj.loginSource = "3"),
      (registerObj.deviceType = "1"),
      (registerObj.city = this.getLocation.city),
      (registerObj.state = this.getLocation.region),
      (registerObj.country = this.getLocation.country),
      (registerObj.zip = this.getLocation.postal),
      // registerObj.roleId = '1'
      // }
      this.authenticationService.register(registerObj).subscribe(
        (res) => {
          if (res !== undefined) {
            if (res.success == true) {
              this.otpEmail = this.registrationForm.value["email"];
              $("#otp").modal("show");
              this.successResponse(res.message);
              this.is_loading = false;
            } else {
              this.errMessageResponse(res.message);
              this.is_loading = false;
            }
            // this.sendVerificationOTP(registerObj.emailId);
            // if (res.status) {
            //   $('#otp').modal('hide');
            //   this.successResponse(res.msg);
            //   this.is_loading = false;
            //   this.registrationForm.reset();
            //   $('#signupActive').removeClass('active');
            //   $('#signinActive').addClass('active"');

            //   $('#signin').css('display', 'block');
            //   $('#signup').css('display', 'none');
            //   BaseComponent.isLogin = true;
            //   this.isLogin = true;
            //   BaseComponent.user = res.user_id;
            //   this.setUserId(res.user_id);
            //   BaseComponent.user = this.getUserId();
            //   var body = res.token;
            //   var base64Url = body.split('.')[1];
            //   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            //   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            //   }).join(''));
            //   this.refereshToken = JSON.parse(jsonPayload);

            //   var dt = new Date(this.refereshToken['exp'] * 1000);
            //   BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();

            //   this.setUserToken('Bearer ' + res.token);
            //   this.is_loading = false;
            //   this.headerService.reloadHeader.emit(true);
            //   this.headerService.setLogin.emit(true);
            //   this.router.navigate(['', 'home']);
            // } else {
            //   this.errMessageResponse(res.msg);
            //   this.is_loading = false;
            // }
          }
        },
        (err) => {
          this.is_loading = false;
        }
      );
  }

  veryfyOTP(): void {
    this.is_loading = true;
    const veryfyOTPObj = new VerifyOtp();
    // verifyOtp.email = this.registrationForm.value['email'];
    // verifyOtp.device_type = '2';
    // verifyOtp.first_name = this.registrationForm.value['first_name'];
    // verifyOtp.last_name = this.registrationForm.value['last_name'];
    // verifyOtp.password = this.registrationForm.value['password'];
    // verifyOtp.dob = moment(this.registrationForm.value['dob']).format('MM DD YYYY');
    // verifyOtp.otp = this.otpform.value['otp'];

    // const registerRequestObject = {
    (veryfyOTPObj.emailId = this.otpEmail),
      (veryfyOTPObj.otp = this.otpform.value["otp"]),
      // }
      this.authenticationService.verifyOTP(veryfyOTPObj).subscribe(
        (res) => {
          if (res !== undefined) {
            if (res.success == true) {
              $("#otp").modal("hide");
              this.successResponse(res.message);
              this.is_loading = false;
              this.registrationForm.reset();
              $("#signupActive").removeClass("active");
              $("#signinActive").addClass('active"');

              $("#signin").css("display", "block");
              $("#signup").css("display", "none");
              BaseComponent.isLogin = true;
              this.isLogin = true;
              BaseComponent.user = res.data[0].userId;
              this.setUserId(res.data[0].userId);
              BaseComponent.user = this.getUserId();
              var body = res.data[0].token;
              var base64Url = body.split(".")[1];
              var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
              var jsonPayload = decodeURIComponent(
                atob(base64)
                  .split("")
                  .map(function (c) {
                    return (
                      "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                  })
                  .join("")
              );
              this.refereshToken = JSON.parse(jsonPayload);

              var dt = new Date(this.refereshToken["exp"] * 1000);
              BaseComponent.userTime = moment(dt)
                .subtract(50, "seconds")
                .format("HH:mm:ss")
                .toString();

              this.setUserToken("Bearer " + res.data[0].token);
              this.is_loading = false;
              this.headerService.reloadHeader.emit(true);
              this.headerService.setLogin.emit(true);
              if (
                this.lastDestinationUrl != "/set-new-password" &&
                this.lastDestinationUrl != "/forgot-password" &&
                this.lastDestinationUrl != "/auth" &&
                this.lastDestinationUrl != null
              ) {
                this.router.navigate([this.lastDestinationUrl]);
              } else {
                this.router.navigate(["", "home"]);
              }
            } else {
              this.errMessageResponse(res.message);
              this.is_loading = false;
            }
          }
        },
        (err) => {
          this.is_loading = false;
        }
      );
  }

  loginUser(loginform: FormGroup): void {
    this.is_loading = true;
    const signin = new SignIn();
    const loginRequest = {
      emailId: this.loginform.value.email_id,
      password: this.loginform.value.password,
      loginSource: "3",
    };
    // signin.emailId = this.loginform.value['email_id'];
    // signin.password = this.loginform.value['password'];
    // signin.loginSource = '3';

    this.authenticationService.userLogin(loginRequest).subscribe(
      (res) => {
        if (res !== undefined) {
          if (res.success == true) {
            this.loginform.reset();
            BaseComponent.isLogin = true;
            this.isLogin = true;
            BaseComponent.user = res.data[0].id;
            this.setUserId(res.data[0].id);
            BaseComponent.user = this.getUserId();
            var body = res.data[0].token;
            var base64Url = body.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
              atob(base64)
                .split("")
                .map(function (c) {
                  return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken["exp"] * 1000);
            BaseComponent.userTime = moment(dt)
              .subtract(50, "seconds")
              .format("HH:mm:ss")
              .toString();
            this.setUserToken("Bearer " + res.data[0].token);
            this.successResponse(res.message);
            this.is_loading = false;
            this.headerService.reloadHeader.emit(true);
            this.headerService.setLogin.emit(true);
            setTimeout(() => {
              if (
                this.lastDestinationUrl != "/set-new-password" &&
                this.lastDestinationUrl != "/forgot-password" &&
                this.lastDestinationUrl != "/auth" &&
                this.lastDestinationUrl != null
              ) {
                this.router.navigate([this.lastDestinationUrl]);
              } else {
                this.router.navigate(["", "home"]);
              }
            });
          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      (error) => {
        if (error.status == 400) {
          this.is_loading = false;
          this.otpEmail = this.loginform.value.email_id;
          this.sendVerificationOTP();
        } else if (error.status == 401) {
          this.errMessageResponse("Invalid Credentials");
        }

        this.is_loading = false;
      }
    );
  }

  public socialSignIn(socialPlatform: string, originFrom: string): void {
    // let socialPlatformProvider;
    // let loginSource;

    // this.authService.signOut();
    if (socialPlatform == "facebook") {
      this.authService
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((userData) => {
          if (userData !== undefined) {
            this.socialLogin(userData, originFrom, "2");
          }
        });
    } else if (socialPlatform == "google") {
      this.authService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((userData) => {
          if (userData !== undefined) {
            this.socialLogin(userData, originFrom, "1");
          }
        });
    }
  }

  public socialLogin(data, originFrom: string, loginSource: string): void {
    this.is_loading = true;
    const pageRequest = { emailId: data.email };
    this.authenticationService
      .checkRegistreUser(pageRequest)
      .subscribe((res) => {
        if (
          res !== undefined &&
          res.success === true &&
          originFrom != "social_login"
        ) {
          this.errMessageResponse(res.message);
          this.is_loading = false;
          return;
        } else if (
          res !== undefined &&
          res.success === true &&
          originFrom == "social_login"
        ) {
          this.sendSocialLogin(data, loginSource);
        } else if (
          res !== undefined &&
          res.success === false &&
          originFrom == "social_register"
        ) {
          this.sendSocialRegister(data, loginSource);
        } else if (
          res !== undefined &&
          res.success === false &&
          originFrom == "social_login"
        ) {
          this.errMessageResponse(res.message);
          this.is_loading = false;
          return;
        }
      });
  }

  public sendSocialRegister(data, loginSource) {
    const registerRequest = {
      emailId: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      // dateOfBirth: "",
      loginSource: loginSource,
      deviceType: "1",
      accessToken: data.authToken,
      city: this.getLocation.city,
      state: this.getLocation.region,
      country: this.getLocation.country,
      zip: this.getLocation.postal,
      // roleId:
    };

    this.authenticationService.register(registerRequest).subscribe(
      (res) => {
        if (res !== undefined) {
          if (res.success === true) {
            BaseComponent.isLogin = true;
            this.isLogin = true;
            BaseComponent.user = res.data[0].id;
            this.setUserId(res.data[0].id);
            BaseComponent.user = this.getUserId();
            var body = res.data[0].token;
            var base64Url = body.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
              atob(base64)
                .split("")
                .map(function (c) {
                  return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken["exp"] * 1000);
            BaseComponent.userTime = moment(dt)
              .subtract(50, "seconds")
              .format("HH:mm:ss")
              .toString();
            this.setUserToken("Bearer " + res.data[0].token);
            this.successResponse(res.message);
            this.is_loading = false;
            this.headerService.reloadHeader.emit(true);
            this.headerService.setLogin.emit(true);
            if (
              this.lastDestinationUrl != "/set-new-password" &&
              this.lastDestinationUrl != "/forgot-password" &&
              this.lastDestinationUrl != "/auth" &&
              this.lastDestinationUrl != null
            ) {
              this.router.navigate([this.lastDestinationUrl]);
            } else {
              this.router.navigate(["", "home"]);
            }
            this.is_loading = false;
          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      (err) => {
        if (err.status == 400) {
          this.is_loading = false;
          var base64Url = err.error.split(".")[1];
          var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          var jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          var response = JSON.parse(jsonPayload);
          this.errMessageResponse(response.message);
          // this.otpEmail = this.loginform.value.email_id;
          // this.sendVerificationOTP();
        }
      }
    );
  }

  public sendSocialLogin(data, loginSource) {
    const loginRequest = {
      emailId: data.email,
      loginSource: loginSource,
      accessToken: data.authToken,
    };

    this.authenticationService.userLogin(loginRequest).subscribe(
      (res) => {
        if (res !== undefined) {
          if (res.success === true) {
            BaseComponent.isLogin = true;
            this.isLogin = true;
            BaseComponent.user = res.data[0].id;
            this.setUserId(res.data[0].id);
            BaseComponent.user = this.getUserId();
            var body = res.data[0].token;
            var base64Url = body.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
              atob(base64)
                .split("")
                .map(function (c) {
                  return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken["exp"] * 1000);
            BaseComponent.userTime = moment(dt)
              .subtract(50, "seconds")
              .format("HH:mm:ss")
              .toString();
            this.setUserToken("Bearer " + res.data[0].token);
            this.successResponse(res.message);
            this.is_loading = false;
            this.headerService.reloadHeader.emit(true);
            this.headerService.setLogin.emit(true);
            if (
              this.lastDestinationUrl != "/set-new-password" &&
              this.lastDestinationUrl != "/forgot-password" &&
              this.lastDestinationUrl != "/auth" &&
              this.lastDestinationUrl != null
            ) {
              this.router.navigate([this.lastDestinationUrl]);
            } else {
              this.router.navigate(["", "home"]);
            }
            this.is_loading = false;
          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      (err) => {
        if (err.status == 400) {
          this.is_loading = false;
          var base64Url = err.error.split(".")[1];
          var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          var jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          var response = JSON.parse(jsonPayload);
          this.errMessageResponse(response.message);
          // this.otpEmail = this.loginform.value.email_id;
          // this.sendVerificationOTP();
        } else if (err.status == 401) {
          this.sendSocialRegister(data, loginSource);
        }

        this.is_loading = false;
      }
    );
  }
}
