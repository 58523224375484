export class registerRequest { //Tejash
    emailId: any;
    password?: any;
    firstName: string;
    lastName: any;
    dateOfBirth?: any;
    loginSource: any;
    deviceType: any;
    city: string;
    state: string;
    country: string;
    zip: string;
    roleId?: any;
    accessToken?: any;
}
export class sendOTPRequest {
    emailId: any;
}

export class registerResponse { //Tejash
    success: boolean;
    message: string;
    data: any;
    links: any;
    iat: any;
  }

export class VerifyOtp {  //Tejash
    emailId: any;
    otp: any;
}

export class otpResponse {
    success: boolean;
    message: string;
    data: any;
    iat: any;
  }

export class SignIn {
    emailId: any;
    password?: any;
    loginSource: any;
    accessToken?: any;
}

export class ForgotPassword { //Tejash
    emailId: string;
}

export class SignInResponse {
    success: boolean;
    message: string;
    data: any;
    iat: any;
}

export class UpdatePassword {
    emailId: any;
    otp: any;
    password: any;
}


export class SendOTPResponse {
    success: boolean;
    message: string;
    data: any;
    links: any;
}

export class SignUpResponse {
    status: boolean;
    msg: string;
    user_id: string;
    name: any;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    country: any;
    state: any;
    dob: string;
    key: string;
    token: string;
}

export class ForgotPasswordResponse {
    success: boolean;
    message: string;
    data: any;
    links: any;
    iat: any;
}

export class UpdatePasswordResponse {
    success: boolean;
    message: string;
    data: any;
    links: any;
}