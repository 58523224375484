import { DOCUMENT } from '@angular/common';
import { Component, OnInit, NgZone, Inject, Renderer2 } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { HomeService } from '@home/services/home.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  PageCategoryData,
  PageCategory,
  PageOrientation,
  PageData,
  PageGenre
} from '@home/model/pagecategory.model';

declare var $: any;

@Component({
  selector: 'app-genres-listing',
  templateUrl: '../view/genres-listing.component.html',
  styleUrls: ['../css/genres-listing.component.css']
})
export class GenresListingComponent extends BaseComponent implements OnInit {
  constructor(private ngZone: NgZone, activateRoute: ActivatedRoute,
              private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.homeService = ServiceLocator.injector.get(HomeService);
    this.route = activateRoute;
  }

  public homeService: HomeService;
  pageCategory: PageCategory;
  pageDataList: PageCategory;
  navigationpath: string;
  navigationtype: string;
  path: string;
  genrePath: any;
  selectedGenrePath: string;
  skip: number;
  loading: boolean;
  viewmore: boolean;
  genreTitle: string;

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');

    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    this.skip = 0;

    this.route.params.subscribe((params: Params) => {
      this.navigationpath = params['navigationpath'];
      this.navigationtype = 'primary_navigation';
      this.path = params['path'];
      this.pageDataList = null;
      this.pageCategory = null;
      if (params['genrePath'] != null && (params['navigationpath'] === '0' || params['navigationpath'] === null)) {
        this.genrePath = params['genrePath'];
        this.getGenreList(true, params['genrePath'], 24, true);
      } else if(params['genrePath'] != null && (params['navigationpath'] != '0' || params['navigationpath'] != null)){
        this.getGenreList(true, params['genrePath'], 24, true);
      } else {
        this.getGenreList(true, 0, 24, true);
        this.getPageCategorie(this.navigationpath, this.navigationtype);
      }
    });
  }

  public getPageCategorie(
    navigationpath: string,
    navigationtype: string
  ): void {
    const pageCategoryRequest = {
      path: navigationpath,
      type: navigationtype,
      deviceTypeId: "1"
    };

    let fiterStringPageCategory = "?filter="+encodeURI(JSON.stringify(pageCategoryRequest));
    this.homeService.getPageCategories(fiterStringPageCategory).subscribe(
      res => {
        if (res !== undefined) {
          let pageCategoryData: PageCategoryData = new PageCategoryData();
          pageCategoryData = res;

          for (const category of pageCategoryData.data) {
            if (category.path == this.path) {
              this.pageCategory = category;

              for (let pageGenre of this.pageCategory.genre) {
                if (pageGenre.path == this.path) {
                  this.selectedGenrePath = pageGenre.name;
                  this.genreTitle = pageGenre.name;
                }
              }

              if (this.selectedGenrePath == null) {
                this.selectedGenrePath = this.pageCategory.title;
                this.genreTitle = this.pageCategory.title;
              }

              $('#category_genre').trigger('destroy.owl.carousel');

              setTimeout(() => {
                this.refreshGenreList();
              }, 50);
            }
          }
        }
      },
      err => {
      }
    );
  }

  public refreshGenreList(): void {
    $('#category_genre').owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 3
        }
      }
    });
  }

  public getGenreList(loadData, genrePath, skip, reset): void {
    if (reset) {
      this.pageDataList = null;
      this.skip = 0;
    } else {
      this.skip = this.skip + skip;
    }
    this.genrePath = genrePath;
    $("#category_genre_" + genrePath + " li a").removeClass("active");

    $("#page_subcategory_item_" + genrePath + "_" + genrePath + " a").addClass(
      "active"
    );
    if(genrePath == 0) {
      const pageListRequest = {
        path: this.path,
        deviceTypeId: "1",
      }
      this.getGenredata(loadData, pageListRequest);
    } else {
      const pageListRequest = {
        path: this.path,
        deviceTypeId: "1",
        genre: [genrePath]
      };
      this.getGenredata(loadData, pageListRequest)
    }
  }

  getGenredata(loadData, pageListRequest) {
    let fiterString = "?filter="+encodeURI(JSON.stringify(pageListRequest))+"&start="+ this.skip +"&limit=24"; 
    this.homeService.getDefualtPageList(fiterString).subscribe(
      res => {
        if (res !== undefined) {
          this.genreTitle = res.data[0].title;
          if (res.data[0].data.length === 24) {
            this.viewmore = true;
          } else {
            this.viewmore = false;
          }
          if (loadData != true) {
            res.data[0].data.forEach(record => {
              this.pageDataList.data.push(record);
            });
          } else {
            this.pageDataList = null;
            this.pageDataList = res.data[0];
          }
          this.loading = false;
        }
      },
      err => {
        this.loading = false;
      }
    );
  }

  public gotoVideoPage(pagedata: PageData): void {
    if (pagedata.type === 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join('').toLowerCase(), pagedata.path]);
    } else if (pagedata.type === 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
  }
}
