import { Injectable } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { ApiService } from '@base/services/base.api.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable, config } from 'rxjs';
import {
  METHOD_REFERENCE,
  API_REFERENCE,
  API_PATH
} from '@base/constants/base.constants';
import {
  VerifyOtp,
  SignIn,
  SignInResponse,
  ForgotPassword,
  UpdatePassword,
  SendOTPResponse,
  SignUpResponse,
  ForgotPasswordResponse,
  UpdatePasswordResponse,
  registerRequest,
  registerResponse,
  otpResponse
} from '@authentication/model/authentication.model';
import {
  AuthServiceConfig,
  FacebookLoginProvider
} from 'angularx-social-login';

@Injectable()
export class AuthenticationService {
  public apiService: ApiService;
  public baseComponent: BaseComponent;

  constructor() {
    this.apiService = ServiceLocator.injector.get(ApiService);
    this.baseComponent = ServiceLocator.injector.get(BaseComponent);
  }

  public sendOTPEmail(email): Observable<SendOTPResponse> {
    
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['authentication']['sendOTP'],
      email,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public register(register: registerRequest): Observable<registerResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['user']['register'],
      register,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public verifyOTP(verifyOTP: VerifyOtp): Observable<otpResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['authentication']['verfyOTP'],
      verifyOTP,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public userLogin(signin: SignIn): Observable<SignInResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['user']['login'],
      signin,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public forgotPassword(
    forgotpassword: ForgotPassword
  ): Observable<ForgotPasswordResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['user']['forgotPassword'],
      forgotpassword,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public setNewPassword(
    updatePassword: UpdatePassword
  ): Observable<UpdatePasswordResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['user']['updatePassword'],
      updatePassword,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  // public getsocialLogin(
  //   email: string,
  //   first_name: string,
  //   last_name: string,
  //   token: string,
  //   name: string,
  //   origin: string
  // ): Observable<any> {
  //   const options = this.baseComponent.getRequestOptions(
  //     METHOD_REFERENCE['GET'],
  //     API_REFERENCE['user']['socialauth'] +
  //     {},
  //     null,
  //     null,
  //     null,
  //     false,
  //     true,
  //     API_PATH
  //   );
  //   return this.apiService.apiCall(options);
  // }

  public checkRegistreUser(urlParam): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE['POST'],
      API_REFERENCE['user']['checkuser'],
      urlParam,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }
}
