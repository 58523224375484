import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { VideoPageService } from '@video-page/services/videopage.service';
import { Params, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  VideoPage,
  VideoPageData,
  RelatedVideo,
  CastCrewsData,
  CastCrews
} from '@video-page/model/video-page';
import { Url } from 'url';
import { PageData, DataGenre } from '@home/model/pagecategory.model';
import { HeaderService } from '@header/services/header.service';
import { FavoritesModule } from '@favorite/module/favorites.module';
import * as _ from 'underscore';
import { environment } from '@environments/environment';
import { error } from 'protractor';

declare var $: any;
declare const bitmovin: any;
@Component({
  selector: 'app-video-page',
  templateUrl: '../view/video-page.component.html',
  styleUrls: ['../css/video-page.component.css']
})
export class VideoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  public videopageService: VideoPageService;
  assetPath: string;
  genrepath: any = [];
  primaryNavigatiopath: any = [];
  menuCategotypath: any = [];
  videopageData: VideoPageData;
  videodata: VideoPage;
  relatedVideoData: RelatedVideo;
  suggestedvideos: RelatedVideo;
  playerVisible: boolean;
  videoTitle = '';
  public videoURL: string;
  public videoPoster: string;
  playerObject: any;
  castcrewData: CastCrews;
  director: string;
  cast: string;
  subScriptionData = [];
  handler: any;
  isPremium: boolean;
  casUserWatchAsset: boolean;
  rented: boolean;

  watchListTimeBitmovin: number = 5;
  addedToWatchList: any = 0;
  bookmark_duration: string;
  headerService: HeaderService;
  trailerPlayBackUrl: string;
  isShowVideo: boolean = false;
  isShowImage: boolean = false;
  watchMovie: boolean = false;
  setBookMark: boolean = false;
  same_for_all_geo_zone: boolean = false;
  showVideoError: boolean = false;
  geoStatus: boolean = false;
  user_can_see_asset: boolean = false;
  isIncreaseCount: boolean = false;
  showButton: boolean;
  playTimeOut: any;
  bitMovieUnsubsribe: any;
  noGenre = false;
  isUserLikes: any;
  showTrailerWithoutPayment = false;
  user: boolean;

  SelectedPath: any;
  genreTitle = '';

  constructor(activateRoute: ActivatedRoute,
              private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.route = activateRoute;
  }

  public reloadedVideoPlayer(): void { }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    if(localStorage.getItem('user_token')){
      this.user= true;
    } else {
      this.user = false;
    }
    this.watchMovie = false;
    this.isShowVideo = false;
    $(document).ready(function () {
      $(this).scrollTop(0);

    });

    this.route.params.subscribe((params: Params) => {

      if (this.playerObject != undefined) {
        this.playerObject.destroy();
      }
      this.videopageData = null;
      this.suggestedvideos = null;
      this.relatedVideoData = null;
      this.subScriptionData = null;
      this.showTrailerWithoutPayment = false;
      this.genreTitle = '';
      this.videoURL = '';
      this.addedToWatchList = 0;
      if(this.videopageData != null) {
        this.videopageData[0].playbackUrl = '';
        this.videopageData[0].trailerPlayBackUrl = '';
        this.videopageData[0].horizontalFilePath = '';
      }
      this.trailerPlayBackUrl = '';
      this.videoPoster = '';
      this.playerObject = undefined;
      this.casUserWatchAsset = false;

      window.scroll(0, 0);
      this.assetPath = params['path'];
      const assetDetailRequest =  {"deviceTypeId":"1"};
      const assetDetailFiterString = "?filter="+encodeURI(JSON.stringify(assetDetailRequest)); 
      this.videopageService.getAssetDetails(this.assetPath,assetDetailFiterString).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.videodata = res;
            this.videopageData = res.data;
            this.addedToWatchList = this.videopageData[0].isUserWatched;
            this.videoURL = this.videopageData[0].playbackUrl;
            this.videoPoster = this.videopageData[0].horizontalFilePath;
            this.bookmark_duration = this.videopageData[0].bookmarkDuration;
            if(this.bookmark_duration == null) {
              this.bookmark_duration = '0';
            }
            this.trailerPlayBackUrl = this.videopageData[0].mapTrailerVideo;
            this.isUserLikes = this.videopageData[0].isUserLikes;
            this.isPremium = this.videopageData[0].isPremium
            this.casUserWatchAsset = this.videopageData[0].casUserWatchAsset;
            if (this.trailerPlayBackUrl == '') {
              this.showButton = false;
            } else if (this.trailerPlayBackUrl == null) {
              this.showButton = false;
            } else if (!this.trailerPlayBackUrl) {
              this.showButton = false;
            } else {
              this.showButton = true;
            }
            this.same_for_all_geo_zone = this.videopageData[0].sameForAllZone;
            this.geoStatus = this.videopageData[0].geoStatus;
            // this.user_can_see_asset = this.videopageData.user_can_see_asset;

            this.getRelatedSuggestedVideos();
            this.getCastCrew(this.videopageData[0].path);
            this.getGenreTitle(this.videopageData);

            $('#posterImage').css('display', '');

            if (this.user == true && this.geoStatus && !this.isPremium) {
                $('#premium-id').removeClass('premium-vod');
              this.watchMovie = true;
              this.isShowVideo = true;
              this.setBookMark = true;
              this.isShowImage = false;
              this.showVideoError = false;
              $('#image-display').hide();
              this.showVideo(this.videoURL, this.videoPoster, true, false);
              this.isIncreaseCount = true;
            } else if (this.user == true && this.geoStatus && this.isPremium && this.casUserWatchAsset) {
                $('#premium-id').removeClass('premium-vod');
              this.watchMovie = true;
              this.isShowVideo = true;
              this.setBookMark = true;
              this.isShowImage = false;
              this.showVideoError = false;
              $('#image-display').hide();
              this.showVideo(this.videoURL, this.videoPoster, true, false);
              this.isIncreaseCount = true;
            } else if (this.user == true && !this.geoStatus) {
              this.showVideoError = true;
              this.isShowImage = false;
              this.isShowVideo = false;
              this.playerObject = undefined;
              this.watchMovie = false;
              this.setBookMark = false;
            } else if (this.user == false && this.geoStatus && !this.isPremium) {
              $('#premium-id').removeClass('premium-vod');
              // this.setBookMark = false;
              // this.showVideoError = false;
              // this.watchMovie = false;
              // this.isShowVideo = false;
              // this.isShowImage = true;
              // this.playerObject = undefined;
              this.watchMovie = true;
              this.isShowVideo = true;
              this.setBookMark = false;
              this.isShowImage = false;
              this.showVideoError = false;
              $('#image-display').hide();
              this.showVideo(this.videoURL, this.videoPoster, true, false);
              this.isIncreaseCount = false;
            } else if (this.user == false && !this.geoStatus) {
              this.showVideoError = true;
              this.isShowImage = false;
              this.isShowVideo = false;
              this.playerObject = undefined;
              this.watchMovie = false;
              this.setBookMark = false;
              this.isIncreaseCount = false;
            } else if(this.user == true && this.geoStatus && this.isPremium && !this.casUserWatchAsset) {
              this.watchMovie = false;
              this.isShowVideo = true;
              this.setBookMark = false;
              this.isShowImage = false;
              this.showVideoError = false;
              this.subScriptionData = null;
              $('#image-display').show();
              const subscriptionRequest =  {"deviceId":"1","assetId": this.assetPath};
              const subscriptionFiterString = "?filter="+encodeURI(JSON.stringify(subscriptionRequest)); 
              this.videopageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
                if(res != undefined && res.success == true) {
                const rentPlan = res.data.data;
                for (let plan of rentPlan) {
                    if(plan.subscriptionType == "TVOD") {
                      this.rented = true;
                      break;
                    }
                  }
                }
              })
              this.showVideo(this.videoURL, this.videoPoster, false, false);
              } else if(this.user == false && this.geoStatus && !this.isPremium) {
                $('#premium-id').removeClass('premium-vod');
                $('#image-display').hide();
                this.watchMovie = true;
                this.isShowVideo = true;
                this.setBookMark = false;
                this.isShowImage = false;
                this.showVideoError = false;
                this.showVideo(this.videoURL, this.videoPoster, true, false);
              } else if(this.user == false && this.geoStatus && this.isPremium) {
                this.watchMovie = false;
                this.isShowVideo = true;
                this.setBookMark = false;
                this.isShowImage = false;
                this.showVideoError = false;
                this.subScriptionData = null;
                const subscriptionRequest =  {"deviceId":"1","assetId": this.assetPath};
                const subscriptionFiterString = "?filter="+encodeURI(JSON.stringify(subscriptionRequest)); 
                this.videopageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
                  if(res != undefined && res.success == true) {
                  const rentPlan = res.data.data;
                  for (let plan of rentPlan) {
                      if(plan.subscriptionType == "TVOD") {
                        this.rented = true;
                        break;
                      }
                    }
                  }
                })
                $('#image-display').show;
                this.showVideo(this.videoURL, this.videoPoster, false, false);
              }
              setInterval(() => {
                if(this.watchMovie == true || this.showTrailerWithoutPayment == true) {
                  this.adjustPlayer();
                }
            }, 1000);
          }
        },
        err => {
          
        }
      );
    });

    $(document).ready(function () {
      $('.vid-description a').click(function () {
        window.open(this.href);
        return false;
      });
    });
  }

  public showTrailer(): void {
    this.showTrailerWithoutPayment = true;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    $('#image-display').hide();
    $("#premium-id").removeClass("premium-vod");
    this.showVideo(this.trailerPlayBackUrl, this.videoPoster, true, this.showTrailerWithoutPayment);
    this.isShowVideo = true;
    this.setBookMark = false;
    this.isShowImage = false;
    this.showVideoError = false;
    this.isIncreaseCount = false;
  }

  public showMovie(): void {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    if (this.user == true && this.geoStatus) {
      this.isShowImage = false;
      this.showVideoError = false;
      this.setBookMark = true;
      this.isShowVideo = true;
      this.showVideo(this.videoURL, this.videoPoster, true, false);
      this.isIncreaseCount = true;
    } else if (this.user == true && !this.geoStatus) {
      this.isShowVideo = false;
      this.isShowImage = false;
      this.showVideoError = true;
      this.setBookMark = false;
      this.playerObject = undefined;
      this.isIncreaseCount = false;
    } else if (this.user == false && this.geoStatus) {
      this.watchMovie = true;
      this.isShowVideo = true;
      this.setBookMark = false;
      this.isShowImage = false;
      this.showVideoError = false;
      this.showVideo(this.videoURL, this.videoPoster, true, false);
      this.isIncreaseCount = false;
    } else if (this.user == false && !this.geoStatus) {
      this.showVideoError = true;
      this.isShowImage = false;
      this.isShowVideo = false;
      this.playerObject = undefined;
      this.watchMovie = false;
      this.setBookMark = false;
      this.isIncreaseCount = false;
    }


  }

    showVideo(videoUrl: string, posterImage: string, autoPlay: boolean, showTrailer) {
    var self = this
    if(this.isPremium == true && this.casUserWatchAsset == true) {
      this.rented = false;
    }
    if (autoPlay && this.playerObject !== undefined) {
      this.playerObject.pause();
    }


    if(this.watchMovie == true || this.showTrailerWithoutPayment == true) {
    setTimeout(() => {
      let source = {}
      source = {
        hls: videoUrl,
        poster: posterImage,
        // analytics: {
        //   videoId: this.assetPath,
        //   title: this.videopageData[0].title
        // }
      };
      if (this.assetPath) {
        var vodId = this.assetPath
      }
      if (this.videopageData[0].title) {
        var vodTitle = this.videopageData[0].title
      }
      const config = {
        key: 'e402008a-94c7-4288-912b-2e4ab92d5d14',
        analytics: {
          key: 'ac814c2b-40b6-4085-8d3d-073db406b5d2',
          videoId: vodId,
          title: vodTitle
        },
        playback: {
          autoplay: autoPlay
        }
      };
      var onTimeChanged = function (data) {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        if (self.setBookMark) {
          addToBookmarkOnTimeUpdateJavascript();
        }
        if (self.addedToWatchList == 0) {
          if (
            BaseComponent.baseUser !== undefined &&
            Object.keys(BaseComponent.baseUser).length !== 0) {
            self.addToWatchlist();
            self.playerObject.player.off('TimeChanged', () => { });
          }
        }
      };

      var addToBookmarkOnTimeUpdateJavascript = function () {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        if (currPlaybackTime != 0) {
          let diffInTime = 0;
          if (parseInt(currPlaybackTime) > parseInt(self.bookmark_duration)) {
            diffInTime = parseInt(currPlaybackTime) - parseInt(self.bookmark_duration);
          } else {
            diffInTime = parseInt(self.bookmark_duration) - parseInt(currPlaybackTime);
          }
          if (diffInTime >= self.watchListTimeBitmovin) {
            addToBookmarkJavascript(currPlaybackTime);
          }
        }
      };

      var onTimePaused = function (data) {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        addToBookmarkJavascript(currPlaybackTime);
        self.playerObject.off('Paused', () => { });
      };

      var addToBookmarkJavascript = function (current_playback_position) {
        if (current_playback_position != 0) {
          if (self.setBookMark) {
            if (
              BaseComponent.baseUser !== undefined &&
              Object.keys(BaseComponent.baseUser).length !== 0
            ) {
              const pageRequest = {
                assetId: self.videopageData[0].path,
                duration: current_playback_position
              };
              self.videopageService.addToBookmark(pageRequest).subscribe(result => {
                if (result !== undefined && result.success == true) {
                  self.bookmark_duration = result.data.duration;
                }

              });
            }
          }
        }

      };

      var onVideoPlay = function () {
        
        self.getFacebookPixle();
      };

      if (this.playerObject === undefined) {
        const container = document.getElementById('bitmovin-player');
        const player = new bitmovin.player.Player(container, config);
        player.on(bitmovin.player.PlayerEvent.TimeChanged, onTimeChanged);
        player.on(bitmovin.player.PlayerEvent.Paused, onTimePaused);
        player.on(bitmovin.player.PlayerEvent.Play, onVideoPlay);

        this.playerObject = player;
      }
      if(self.watchMovie == true || showTrailer == true) {
      this.bitMovieUnsubsribe = this.playerObject.load(source).then(function (player) {
        var container = $('.player-container');
        var playerHeight = $('#bitmovin-player').height();
        self.seekPlayer(0, true, self.setBookMark);
        if (autoPlay) {
          setTimeout(() => {
            self.playerObject.play();
          }, 1000);
        }
        if (container.height() <= playerHeight) {
          container.height(playerHeight);
        }
        if(showTrailer == true && self.casUserWatchAsset == false && self.isPremium == true) {
          self.playerObject.on(bitmovin.player.PlayerEvent.Play, function () {
            $("#premium-id").removeClass("premium-vod");
            self.showTrailerWithoutPayment = true;
          })
          self.playerObject.on(bitmovin.player.PlayerEvent.PlaybackFinished, function () {
            $('#fixed_player').removeClass('fixed-player');
            $('#image-display').show();
            $("#premium-id").addClass("premium-vod");
            self.showTrailerWithoutPayment = false;
            self.showVideo(self.videoURL, self.videoPoster, false, false);
          })
        }
        self.playTimeOut = setTimeout(() => {
          if ((self.playerObject !== undefined && self.watchMovie == true) || showTrailer== true) {
            self.playerObject.play();
          } else if ((self.playerObject !== undefined && self.watchMovie == false) || showTrailer == true) {
            self.playerObject.pause();
          }
        }, 1000);

      }, function (reason) {
        
      });
    }

    }, 0.8);
  }
  }

  adjustPlayer() {
    if(this.showTrailerWithoutPayment == true || this.watchMovie == true) {
    const container = $('.player-container');

    /* extract constants for better readabilty */
    const lowerEdge = container.height();
    const switchToMinPlayerPos = lowerEdge - (window.innerHeight / 16);
    const currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;

    /* toggle the css-class responsible for the player moving to the lower right corner */
    if (currentScrollPos > switchToMinPlayerPos) {
      $('.player-switch').addClass('fixed-player');
      if(document.getElementById("pip-close")) {
        document.getElementById("pip-close").onclick = function() {closePip()};
      }
    } else {
      $('.player-switch').removeClass('fixed-player');
    }
    function closePip() {
      window.scroll(0, 0)
    }
  }
}

  seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {

    if (videoDuration) {
      if (setBookMark) {
        this.playerObject.seek(this.playerObject.getCurrentTime() + this.bookmark_duration);
      }
    } else {
      this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
    }


  }

  refreshHorizontalCarousel(carouselId: string): void {
    $('#' + carouselId).owlCarousel({
      loop: false,
      margin: 21,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });
  }

  addToFav() { }

  refreshVerticalCarousel(carouselId: string): void {
    $('#' + carouselId).owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });
  }

  public getAssetDetails(path: string): void {

  }

  public addTofavourite(path: string): void {
    let userId:any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        // const pageRequest = {
        //   //user_id: this.getUserId(),
        //   asset_id: path
        // };
        const pageRequest = {
          assetId:path
        }

        this.videopageService.addTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              // this.videopageData.isUserLikes = true;
              this.isUserLikes = 1;
              this.successResponse(res.message);
              //this.successMessageResponse();
              //this.successMessageResponse('remove to fav');
            } else if (res !== undefined && res.success === false) {
              this.errMessageResponse(res.message);
            }
          },
          err => {
            
          }
        );

      }
    } else {
      this.warningMessageResponse('Please sign in to Add to Favourite');
    }
  }

  public removeTofavourite(path): void {
    let userId:any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        const pageRequest = {
          assetId: path
        };

        this.videopageService.removeTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              this.isUserLikes = 0;
              this.successResponse(res.message);
            }
          },
          err => {
            
          }
        );
      }

    }

  }

  public addToWatchlist(): void {
    if(this.watchMovie == true || this.showTrailerWithoutPayment == true) {
    const pageRequest = {
      assetId: this.videopageData[0].path
    };
    // let filterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
    this.videopageService.addToWatchlist(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          // this.videopageData.watchedCount += 1;
        }
      },
      err => {
        
      }
    );
  }
  }

  public getRelatedSuggestedVideos(): void {
    this.genrepath = [];
    this.primaryNavigatiopath = [];
    this.menuCategotypath = [];
    if (this.videopageData[0].genre.length > 0) {
      // this.genrepath = _.pluck(this.videopageData[0].genre,"genreId");
      const genres = this.videopageData[0].genre
      for (let genre of genres) {
        this.genrepath.push(Number(genre.genreId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    if (this.videopageData[0].primaryNavigation.length > 0) {
      // this.primaryNavigatiopath = this.videopageData[0].primaryNavigation[0].primaryNavigationId;
      const primaryNavigation = this.videopageData[0].primaryNavigation
      for (let navigation of primaryNavigation) {
        this.primaryNavigatiopath.push(Number(navigation.primaryNavigationId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    if (this.videopageData[0].menuCategory.length > 0) {
      // this.menuCategotypath = _.pluck(this.videopageData[0].menuCategory,'menuCategoryId');
      const menuCategory = this.videopageData[0].menuCategory
      for (let category of menuCategory) {
        this.primaryNavigatiopath.push(Number(category.menuCategoryId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    const pageRequest = {
      deviceTypeId: 1,
      genre: this.genrepath,
      language: this.videopageData[0].langaugeId,
      primaryNavigation: this.primaryNavigatiopath,
      menuCategory: this.menuCategotypath
    };
    let filterString = "?filter="+encodeURI(JSON.stringify(pageRequest))+"&start=0&limit=15";

    this.videopageService.getRelatedVideo(filterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.relatedVideoData = res.data;
          $('#recommended_carousel_list').trigger('destroy.owl.carousel');

          setTimeout(() => {
            this.refreshHorizontalCarousel('recommended_carousel_list');
          }, 20);
        }
      },
      err => {
        
      }
    );
    this.videopageService.getSuggestedvideos(filterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.suggestedvideos = res.data;
          var carouselId = 'may_like_carousel_list';

          $('#' + carouselId).trigger('destroy.owl.carousel');

          setTimeout(() => {
            this.refreshVerticalCarousel(carouselId);
          }, 50);
        }
      },
      err => {
        
      }
    );
  }

  public getCastCrew(assetpath: string): void {
    const pageRequest = {"assetId": assetpath};
    let fiterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
    this.videopageService.getCastCrews(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.castcrewData = res.data;
          this.cast = '';
          this.director = '';
          if (this.castcrewData !== null && this.castcrewData.data) {
            for (const castcrew of this.castcrewData.data) {
              if (castcrew.character[0].professionType == 'Director') {
                if (this.director === '') {
                  this.director = ' ' + this.director + castcrew.firstName;
                } else {
                  this.director = this.director + ', ' + castcrew.firstName;
                }
              } else if (castcrew.character[0].professionType == 'Cast') {
                if (this.cast === '') {
                  this.cast = ' ' + this.cast + castcrew.firstName;
                } else {
                  this.cast = this.cast + ', ' + castcrew.firstName;
                }
              }
            }
          }
        }
      },
      err => {
        
      }
    );
  }

  public gotoVideoPage(pagedata: PageData): void {
    if (this.playerObject !== undefined) {
      this.playerObject.pause();
    }

    $('#posterImage').css('display', 'none');
    if (pagedata.type === 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.type === 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
  }

  public getGenreTitle(videopageData: VideoPageData): string {
    var genreTitle = '';
    const genres = videopageData[0].genre
      if (genres.length > 0) {
        for (let orientation of genres) {
          if (genreTitle != '') {
            genreTitle += ', ';
          }
          genreTitle += orientation.genreName;
        }
      } else {
        this.noGenre = true;
      }
    this.genreTitle = genreTitle;
    return this.genreTitle
  }

  public addToBookmark(assetDuration: string): void {
    const pageRequest = {
      assetId: this.videopageData[0].path,
      duration: assetDuration
    };
    this.videopageService.addToBookmark(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
        }
      },
      err => {
        
      }
    );
  }

  public goToHomePage(): void {
    this.headerService.reloadHeader.emit(true);
    this.router.navigate(['']);
  }

  public gotToLoginPage(): void {
    this.warningMessageResponse('Please sign in to watch Video');
    this.router.navigate(['auth']);
  }

  public facebookShare(e) {
    var facebookShare = 'https://vuuzle.dcafecms.com';

    e.preventDefault();
    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
      'facebook-popup', 'height=350,width=600');
    if (facebookWindow.focus) {
      facebookWindow.focus();

    }
    return false;
  }

  public twitterShare(e) {
    var twitterShare = 'https://vuuzle.dcafecms.com';

    e.preventDefault();
    var twitterWindow = window.open('https://twitter.com/share?url=' + window.location.href,
      'twitter-popup', 'height=350,width=600');
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;
  }

  // goPremium() {
  //   if(BaseComponent.isLogin == true) {
  //     console.log("go Premium");
  //   } else {
  //     this.router.navigate(['auth']);
  //   }
  // }

  goRent() {
    if(BaseComponent.isLogin == true) {
      $('#rent-list').modal('show');
      this.getPremiumForSubscriptionList();
    } else {
      localStorage.setItem('videoUrl', this.router.url)
      this.router.navigate(['auth']);
    }
  }


  getPremiumForSubscriptionList() {
    this.subScriptionData = null;
    const  subscriptionRequest =  {"deviceId":"1","assetId": this.assetPath};
    const subscriptionFiterString = "?filter="+encodeURI(JSON.stringify(subscriptionRequest)); 
    this.videopageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
      if(res != undefined && res.success == true) {
        this.subScriptionData = res.data.data;
      }
    })
  }

  checkout(item, e) {
      this.handler = (<any>window).StripeCheckout.configure(
        {
          key: environment.stripeKey,
          locale: "auto",
          token: (token: any) => {
            let cardToken = token.id;
            const orderReq = {
              orderId: cardToken,
              assetId: this.assetPath,
              userId: this.getUserId(),
              subscriptionId: item.path,
              isRecurring: 1,
              billingName: BaseComponent.userFName,
              billingEmail: BaseComponent.userEmail,
              status: "Active",
              usageType: "Paid",
              isActive: 1,
              paymentMethod: "stripe"
            };
            this.videopageService.placeOrder(orderReq).subscribe(
              res => {
                if (res !== undefined && res.success === true) {
                    this.successResponse(res.message);
                  $('#rent-list').modal('hide');
                  this.ngOnInit();
                  //this.successMessageResponse();
                  //this.successMessageResponse('remove to fav');
                } else if (res !== undefined && res.success === false) {
                  this.errMessageResponse(res.message);
                }
              },
              err => {
                
              }
            );
          },
        }
      );
      let totalAmount = item.rate * 100;
      if (totalAmount > 0) {
        this.handler.open({
          name: "Vuzzle",
          description: item.title,
          amount: totalAmount,
          email: BaseComponent.userEmail
        });
      }

      e.preventDefault();
    }

  ngOnDestroy() {

    if (this.playerObject !== undefined) {
      this.playerObject.pause();
      this.playerObject.unload();
    }
  }
}
